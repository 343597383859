import { TPlanSummary } from 'common/cms-types'
import { Format } from 'common/utils'
import Multiline from 'components/util/Multiline'
import Strings from 'project/localisation'
import Button from 'components/forms/Button'
import Link from 'next/link'
import useShoppingBag from 'common/useShoppingBag'
import ImageCard from 'components/cards/base/ImageCard'
import { ReactFC } from 'common/types/ReactFC'
import { getPlanSummaryImage } from 'project/getPlanImages'
import { planSummaryStrings } from 'common/strings/plan-summary-strings'
import { planColors, PlanSlug } from 'common/planSlugs'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import Badge from 'components/Badge'
import MostPopularRibbon from 'components/MostPopularRibbon' // we need this to make JSX compile

type PlanSummaryType = {
  plan: TPlanSummary
}

const PlanSummary: ReactFC<PlanSummaryType> = ({ plan }) => {
  const { addPlan, hasPlanInBag, removePlan } = useShoppingBag()
  const hasPlan = hasPlanInBag(plan.slug)
  const router = useRouter()
  const purchase = () => {
    addPlan(plan.slug, 'month', plan)
    router.push('/checkout')
  }
  const remove = () => {
    removePlan(plan)
  }
  const cannotBuy = plan.slug === PlanSlug.MEETINGS
  return (
    <ImageCard
      className={classNames(
        'plan-card-container position-relative rounded-box sm:text-center',
        `plan-${plan.slug}`,
      )}
      padding='0'
      imageAlt={`${planSummaryStrings[plan.shortName]} icon`}
      image={getPlanSummaryImage(plan.slug)}
      classNameForCol1='col-lg-6 pt-xxl-8 pt-5 pb-xxl-7 pb-3 ps-lg-6 pe-xxl-5 px-4'
      classNameForCol2='col-lg-6 align-items-flex-end pb-lg-0 pb-2'
      gutters='g-0'
    >
      <div className='text-lg-start text-center'>
        <h2
          className={`h2-static text-center text-lg-start text-semibold mb-lg-4 mb-2 color-${
            planColors[plan.slug]
          }`}
        >
          {planSummaryStrings[plan.shortName]}
        </h2>
        {plan.recommended && (
          <div className='top-0 position-absolute end-0'>
            <MostPopularRibbon />
          </div>
        )}
        <h3 className='text-center text-lg-start text-semibold text-xxl'>
          <Multiline
            text={`${`${planSummaryStrings[plan.summary] as string}`}`}
          />{' '}
        </h3>
        <p
          className='text-large-2 text-center text-lg-start mb-0 px-lg-0 px-3'
          dangerouslySetInnerHTML={{
            __html: `${planSummaryStrings[plan.description]}`,
          }}
        />
      </div>

      <div className='d-flex flex-1 flex-column justify-content-end'>
        <h3 className='text-xxl text-semibold mt-2 mt-sm-0 mb-3 lead-large text-center text-lg-start'>
          {plan.fees.map((v) => planSummaryStrings[v]).join(', ')}
        </h3>
        <h2 className='h2-static text-semibold mb-0 text-center text-lg-start'>
          {Format.money(plan.pricing.month_price)}{' '}
        </h2>
        <p className='text-center text-lg-start per-month-text mb-0'>
          {plan.priceDuration
            ? Strings[plan.priceDuration]
            : Strings.perMonthPlusVATLower}
        </p>

        <div className='mt-xxl-4 mt-3 d-flex gap-4 d-lg-grid justify-content-lg-start justify-content-center plan-summary-cta'>
          <Link className='a-unstyled' href={`/${plan.slug}`}>
            <Button theme='secondary' size='medium'>
              {Strings.viewPlan}
            </Button>
          </Link>
          {cannotBuy ? (
            <Link className='a-unstyled' href={`/contact-us`}>
              <Button theme='primary' size='medium'>
                {Strings.getInTouch}
              </Button>
            </Link>
          ) : (
            <>
              <Button
                data-test={`buy-${plan.slug}`}
                onClick={purchase}
                theme='primary'
                size='medium'
              >
                {Strings.buy}
              </Button>
            </>
          )}
        </div>
      </div>
    </ImageCard>
  )
}

export default PlanSummary
